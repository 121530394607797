import React from 'react';
import { useNavigate } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useForm, type SubmitHandler } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Translate } from '../../../../../i18n/translate';
import { TextInput, RadioCard } from '../../../../../components';
import { useAppDispatch } from '../../../../../store';
import { selectCurrentCustomer, sendToast } from '../../../../../store/slices/main/mainSlice';
import ApprovalsSerive from '../../approvalsService';

type Inputs = {
  modelName: string;
  appStructure: AppStructureType;
  approverCategory: 'USER' | 'ROLE';
};

type Props = {
  feature: FeatureType;
  isCreateApproval: boolean;
  modelId?: number;
  initialData: Inputs;
  submitStep: (data: Inputs) => void;
};

const Step1 = ({ feature, isCreateApproval, initialData, submitStep, modelId }: Props) => {
  const navigate = useNavigate();

  const currentCustomer = useSelector(selectCurrentCustomer);

  const dispatch = useAppDispatch();

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>({
    defaultValues: initialData,
  });

  const getErrorToastMessage = () => {
    switch (feature) {
      case 'PAYMENT':
        return isCreateApproval
          ? 'create_payment_approval.failed'
          : 'update_payment_approval.failed';
      case 'BENEFICIARY_MANAGEMENT':
        return isCreateApproval
          ? 'create_beneficiary_approval.failed'
          : 'update_beneficiary_approval.failed';
      case 'MANAGE_APPROVAL_WORKFLOW':
        return isCreateApproval
          ? 'create_compliance_approval.failed'
          : 'update_compliance_approval.failed';
      case 'MANAGE_CUSTOMER':
        return isCreateApproval
          ? 'create_customer_approval.failed'
          : 'update_customer_approval.failed';
      case 'MANAGE_USER':
        return isCreateApproval ? 'create_user_approval.failed' : 'update_user_approval.failed';
    }
  };

  const validateMutation = useMutation({
    mutationFn: async (data: Inputs) => {
      const requestBody = {
        ...data,
        owningCustomerId: currentCustomer!.id!,
        feature,
      };
      await ApprovalsSerive.validateApprovalStructure(
        isCreateApproval ? requestBody : { ...requestBody, id: modelId! },
      );
      return data;
    },
    onSuccess: (data) => {
      submitStep(data);
    },
    onError: (error) => {
      dispatch(
        sendToast({
          severity: 'error',
          summary: getErrorToastMessage(),
          detail: error.message ? error.message : getErrorToastMessage(),
        }),
      );
    },
  });

  const submitForm: SubmitHandler<Inputs> = (data) => {
    validateMutation.mutate(data);
  };

  const heading = (() => {
    switch (feature) {
      case 'PAYMENT':
        return 'create_approval.payment.heading';
      case 'BENEFICIARY_MANAGEMENT':
        return 'create_approval.beneficiary.heading';
      case 'MANAGE_USER':
        return 'create_approval.user.heading';
      case 'MANAGE_CUSTOMER':
        return 'create_approval.customer.heading';
      case 'MANAGE_APPROVAL_WORKFLOW':
        return 'create_approval.approval.heading';
      default:
        return '';
    }
  })();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(submitForm)(e);
      }}
      className='grid gap-4'
    >
      <div className='card grid gap-6 lg:px-14 lg:py-6'>
        <div className='grid gap-1'>
          <Translate value={heading} className='text-neutral-1 text-lg-semibold' />
          <Translate
            value='create_approval.subheading'
            className='text-neutral-3 text-sm-regular'
          />
        </div>
        <TextInput
          isRequired
          label='Enter Structure Name'
          placeholder='structure_name'
          className='max-w-[400px]'
          formRegister={register('modelName', { required: true })}
          error={errors.modelName}
          errorLabel='structure_name'
        />
      </div>
      <div className='card lg:px-14 lg:py-6'>
        <Translate
          value='create_approval.approval_structure_type'
          className='text-neutral-1 text-lg-semibold'
        />
        <div className='grid grid-cols-2 lg:grid-cols-4 gap-5 mt-6'>
          <RadioCard
            label='create_approval.simple'
            subLabel='create_approval.simple.description'
            value='SIMPLE'
            control={control}
            name='appStructure'
            disabled={!isCreateApproval}
          />
          <RadioCard
            label='create_approval.sequential'
            subLabel='create_approval.sequential.description'
            value='SEQUENTIAL'
            control={control}
            name='appStructure'
            disabled={!isCreateApproval}
          />
          {feature === 'PAYMENT' && (
            <RadioCard
              label='create_approval.tiered'
              subLabel='create_approval.tiered.description'
              value='TIERED'
              control={control}
              name='appStructure'
              disabled={!isCreateApproval}
            />
          )}
          <RadioCard
            label='create_approval.group'
            subLabel='create_approval.group.description'
            value='GROUPED'
            control={control}
            name='appStructure'
            disabled={!isCreateApproval}
          />
        </div>
      </div>
      <div className='card p-0'>
        <div className='px-4 lg:px-14 py-10 border-b border-neutral-surface-subtle'>
          <Translate
            value='create_approval.assign_approvers'
            className='text-neutral-1 text-lg-semibold'
          />
          <div className='grid grid-cols-2 lg:grid-cols-4 gap-5 pt-6'>
            <RadioCard
              label='create_approval.role_based'
              subLabel='create_approval.role_based.description'
              value='ROLE'
              control={control}
              name='approverCategory'
            />
            <RadioCard
              label='create_approval.user_based'
              subLabel='create_approval.user_based.description'
              value='USER'
              control={control}
              name='approverCategory'
            />
          </div>
        </div>
      </div>
      <div className='flex flex-col sm:flex-row gap-4 w-full justify-center mt-10'>
        <Button
          type='button'
          severity='contrast'
          className='justify-center'
          onClick={() => navigate(`/manage/approvals?tab=${feature}`)}
        >
          <Translate value='create_approval.cancel' />
        </Button>
        <Button
          type='submit'
          severity='info'
          className='sm:w-72 justify-center gap-2'
          loading={validateMutation.isPending}
          disabled={validateMutation.isPending}
        >
          <Translate value='create_approval.submit' />
        </Button>
      </div>
    </form>
  );
};

export default Step1;
