import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { DropdownInput } from '../../../../components';
import { Translate, translateWithValues } from '../../../../i18n/translate';
import { AddAlt } from '../../../../icons';
import { useAppDispatch } from '../../../../store';
import { selectCurrentCustomer, sendToast } from '../../../../store/slices/main/mainSlice';
import { CustomerType, Status } from '../../../../types';
import ClientService from '../../../Client/clientService';
import PaymentService from '../../paymentService';
import CustomerCreationModal from './CustomerCreationModal';

interface CustomerDetailsProps {
  onSaveAndProceed?: (client: CustomerDetails) => void;
  quoteId: string;
  orderId: number | undefined;
  paymentId: number | undefined;
}

interface Option {
  value: string;
  label: string;
}

export const CustomerDetails = ({
  onSaveAndProceed,
  quoteId,
  orderId,
  paymentId,
}: CustomerDetailsProps) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid, errors },
  } = useForm<CustomerDetails>();
  const [options, setOptions] = useState<Option[]>([]);
  const dispatch = useAppDispatch();
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNewCustomer, setIsNewCustomer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getCustomers = async () => {
    setIsLoading(true);
    const customerId = selectedCustomer?.id;
    if (customerId) {
      const payload = {
        customerType: [CustomerType.ORDERING_CUSTOMER],
      };
      const response = await ClientService.getChildClients(
        customerId,
        0,
        0,
        payload,
        '',
        null,
        true,
      );
      if (response?.content) {
        const filterCustomers = response.content.filter(
          (c) =>
            (c.status == Status.ACTIVE ||
              c.approvalStatus == Status.PENDING_APPROVAL ||
              c.approvalStatus == Status.PENDING_CREATION) &&
            c.bankInfos[0]?.accountNumber != null,
        );
        const customerOptions = filterCustomers.map(({ id, customerLegalName }) => ({
          value: `${id}`,
          label: customerLegalName,
        }));
        setOptions(customerOptions);
        setIsLoading(false);
      }
    }
  };

  const handleNewCustomerCreation = (customer: Client) => {
    const newCustomerOption = {
      value: `${customer.id}`,
      label: customer.customerLegalName,
    };
    setOptions([...options, newCustomerOption]);
    reset({ selectedCustomer: `${customer.id}` });
    //    setIsNewCustomer(true);
  };

  const handleDeleteEntry = () => {
    if (isNewCustomer) {
      reset();
    }
    setIsNewCustomer(false);
  };

  useEffect(() => {
    if (quoteId) getCustomers();
  }, [selectedCustomer, quoteId]);

  const saveCustomerDetails = async (formData: CustomerDetails) => {
    const payload = {
      remitterId: formData.selectedCustomer,
      orderId: orderId,
      paymentId: paymentId,
    };
    try {
      await PaymentService.updateRemitter(payload);
      showToast('success', 'success', 'quote_saved.message.success');
      if (onSaveAndProceed) {
        onSaveAndProceed(formData);
      }
    } catch (error) {
      console.log(error);
      showToast('error', 'error', 'quote.remitter.update.error');
    }
  };

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const showErrorToast = () => {
    showToast('error', 'client.field.validation.error', 'client.field.validation.error.detail');
  };

  return (
    <div className='relative'>
      {!quoteId ? (
        <div className='absolute inset-0 bg-white opacity-70 cursor-not-allowed z-10' />
      ) : (
        ''
      )}
      <div className='rounded-c8 shadow-c bg-white p-8'>
        <CustomerCreationModal
          visible={isModalVisible}
          setVisible={setIsModalVisible}
          handleNewCustomer={handleNewCustomerCreation}
          handleDeleteEntry={handleDeleteEntry}
        />
        <div className='flex justify-between flex-wrap'>
          <div>
            <div className='text-sm-bold'>
              <Translate value='customer.title' />
            </div>
            <div className='text-neutral-3'>
              <Translate value='customer.description' />
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className='mt-4'>{translateWithValues('loadingCustomer')}</div>
        ) : (
          /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
          <form onSubmit={handleSubmit(saveCustomerDetails)}>
            <div className='mt-8 flex flex-wrap gap-10 text-neutral-2 text-sm-medium'>
              <DropdownInput
                disabled={isNewCustomer || !quoteId}
                className='lg:min-w-[250px]'
                placeholder='change_customer'
                options={options}
                label='select_existing_customer'
                isRequired
                name='selectedCustomer'
                filter
                control={control}
                error={errors['selectedCustomer']}
                rules={{ required: true }}
              />
              <div className='self-end'>
                <Button
                  severity='contrast'
                  className='h-[43px] w-60'
                  onClick={() => {
                    setIsModalVisible(true);
                  }}
                  type='button'
                >
                  <AddAlt />
                  <Translate value='add_new_customer.header' className='ml-1' />
                </Button>
              </div>
            </div>
            <div className='flex items-center justify-center mt-10 gap-5'>
              <Button
                type='submit'
                severity='info'
                className='w-48 inline-flex items-center justify-center'
                loading={isLoading}
                disabled={!quoteId || isLoading}
                onClick={() => {
                  if (!isValid) {
                    showErrorToast();
                    return;
                  }
                }}
              >
                <Translate value='save_and_proceed' />
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};
