import {
  ArrowRight,
  CheckmarkOutline,
  Close,
  Download as DownloadIcon,
  Filter,
  SendAlt,
  View,
} from '@carbon/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable, DataTablePageEvent, DataTableSortEvent, SortOrder } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  Confirm,
  CurrencyFlag,
  FormatAmount,
  InheritedMenu,
  MoreActionMobile,
  PaginatorTemplate,
  Status,
  TableSearch,
  WarningIcon,
} from '../../../../components';
import { Translate, translateWithValues } from '../../../../i18n/translate';
import { MoreAction } from '../../../../icons';
import NextButton from '../../../../icons/NextButton';
import PrevButton from '../../../../icons/PrevButton';
import { selectCustomerCurrencies } from '../../../../store/slices/refdata/refdataSlice';
import paymentRole from '../../paymentRole';
import PaymentService from '../../paymentService';

import { ProgressSpinner } from 'primereact/progressspinner';
import { useDebounce } from 'use-debounce';
import CountryFlag from '../../../../components/CountryFlag';
import FilterModal from '../../../../components/FilterModal';
import {
  selectCurrentCustomer,
  selectFilters,
  sendToast,
  updateFilter,
} from '../../../../store/slices/main/mainSlice';
import { Status as StatusEnum } from '../../../../types';
import { convertDateFormat } from '../../../../utils/helper';
import { PAYMENT_CATEGORY_OPTIONS, PAYMENT_STATUS_OPTIONS } from '../../paymentConstants';
import './Payments.scss';
interface PaymentsTableProps {
  isDashboard?: boolean;
  filterCategory?: 'PENDING' | 'IN_PROCESSING' | 'PAYMENT_COMPLETED';
  totalRecords: number;
  setTotalRecords: (totalRecords: number) => void;
}

const PaymentsTable = ({
  isDashboard = false,
  filterCategory,
  totalRecords,
  setTotalRecords,
}: PaymentsTableProps) => {
  const [globalSearch, setGlobalSearch] = useState('');
  const [debouncedSearch] = useDebounce(globalSearch, 300);
  const [selectedRates, setSelectedRates] = useState<Order[] | null>(null);
  const [pageParams, setPageParams] = useState<PageParams>({
    first: 0,
    rows: 10,
    page: 0,
  });
  const [totalPages, setTotalPages] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState<Order['id'] | null>(null);
  const [actionItems, setActionItems] = useState<MenuItem[]>([]);
  const menu = useRef<Menu>(null);
  const [sortField, setSortField] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<SortOrder>();
  const navigate = useNavigate();
  const currencies = useSelector(selectCustomerCurrencies);
  const filters = useSelector(selectFilters)['payments_filter'];
  const [activeFilterCount, setActiveFilterCount] = useState(
    filters ? Object.keys(filters).length : 0,
  );
  const [isModalVisible, setModalVisible] = useState(false);
  const [showCancelPaymentConfirmPopup, setShowCancelPaymentConfirmPopup] = useState(false);
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const dispatch = useDispatch();
  const [filterValues, setFilterValues] = useState<any>([
    {
      header: 'By Status',
      fields: [
        {
          name: 'statuses',
          placeholder: 'Select Status',
          value: filters?.statuses || [],
          options: PAYMENT_STATUS_OPTIONS,
        },
      ],
    },
    {
      header: 'By Category',
      fields: [
        {
          name: 'categories',
          placeholder: 'Select Category',
          value: filters?.category || '',
          type: 'single',
          options: PAYMENT_CATEGORY_OPTIONS,
        },
      ],
    },
  ]);

  const rateRequestQuery = useQuery({
    queryKey: [
      'rate_requests_query',
      pageParams,
      sortField,
      sortOrder,
      filters,
      filterCategory,
      selectedCustomer?.id,
      debouncedSearch,
    ],
    queryFn: async () => {
      const { page, rows } = pageParams;
      const payload: {
        statuses: (typeof filters)['statuses'];
        filterCategory?: typeof filterCategory;
        searchText?: string;
      } = {
        statuses: filters?.statuses || [],
        filterCategory: filters?.categories as typeof filterCategory,
      };
      if (debouncedSearch) {
        payload.searchText = debouncedSearch;
      }
      if (filterCategory) {
        payload.filterCategory = filterCategory;
      }
      const data = await PaymentService.getRateRequests(page, rows, payload, sortField, sortOrder);
      setTotalPages(data?.totalPages ?? 0);
      setTotalRecords(data?.totalElements ?? 0);
      return data?.content;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const onPage = (event: DataTablePageEvent) => {
    setPageParams({
      ...event,
      page: event.page ?? 0,
      rows: event.rows ?? 10,
      first: event.first ?? 0,
    });
  };

  const onSort = (event: DataTableSortEvent) => {
    if (event.sortField && event.sortOrder) {
      setSortField(event.sortField);
      setSortOrder(event.sortOrder);
    }
  };

  const getFormattedDate = (date: string) => {
    if (date == '') return 'N/A';

    return convertDateFormat(date);
  };

  const downloadMutation = useMutation({
    mutationFn: async ({ orderId, quoteId }: { orderId: number; quoteId: string }) => {
      const response = await PaymentService.downloadDisclosureStatement(orderId, quoteId);
      return response;
    },
    onError: () => {
      dispatch(
        sendToast({
          severity: 'error',
          summary: 'download_failed',
          detail: 'disclosure_statement_download_failed',
        }),
      );
    },
  });

  const sendDisclosureEmailMutation = useMutation({
    mutationFn: async (orderId: number) => {
      const response = await PaymentService.sendDisclosureEmail(orderId);
      return response;
    },
    onSuccess: () => {
      dispatch(
        sendToast({
          severity: 'success',
          summary: 'success',
          detail: 'disclosure_email_sent',
        }),
      );
    },
    onError: () => {
      dispatch(
        sendToast({
          severity: 'error',
          summary: 'failed',
          detail: 'disclosure_email_failed',
        }),
      );
    },
  });

  const actionBodyTemplate = (order: Order) => {
    const actions: MenuItem[] = [
      {
        roles: paymentRole.viewPayments,
        label: 'viewDetails',
        icon: <View />,
        onClick: () => order.id && navigate(`/payment/rate-requests/view?orderId=${order.id}`),
      },
      // {
      //   roles: paymentRole.editPayments,
      //   label: 'edit_payment',
      //   icon: <Edit />,
      //   onClick: () =>
      //     rateRequest.payments[0].quote.quoteId &&
      //     navigate('/payment/rate-requests/edit/' + rateRequest.payments[0].quote.quoteId),
      // },
    ];

    if (
      order.status === StatusEnum.PENDING_APPROVAL ||
      order.status === StatusEnum.READY_FOR_RELEASE
    ) {
      actions.push(
        ...[
          {
            label: 'download_payment',
            icon: <DownloadIcon />,
            onClick: () =>
              void downloadMutation.mutate({
                orderId: order.payments[0].orderId,
                quoteId: order.payments[0].quote.quoteId,
              }),
          },
          {
            label: 'send_via_email',
            icon: <SendAlt />,
            onClick: () => void sendDisclosureEmailMutation.mutate(order.payments[0].orderId),
          },
        ],
      );
    }

    if (order.canBeApprovedByUser && order.status === StatusEnum.PENDING_APPROVAL) {
      actions.unshift({
        roles: paymentRole.approvePayments,
        label: 'payments.table.approve.action',
        onClick: () => {
          navigate(`/payment/rate-requests/approve?orderId=${order.id}`);
        },
        icon: <CheckmarkOutline />,
      });
    }
    if (![StatusEnum.REJECTED, StatusEnum.CANCELLED, StatusEnum.ABANDONED].includes(order.status)) {
      actions.push({
        label: 'payments.table.cancel_payment',
        icon: <Close />,
        className: 'delete-menu-item',
        onClick: () => setShowCancelPaymentConfirmPopup(true),
      });
    }

    return (
      <>
        <div>
          <div
            className='hidden sm:block'
            onClick={(event) => {
              setActionItems(actions);
              setSelectedOrder(order.id);
              menu.current?.toggle(event);
            }}
          >
            <MoreAction />
          </div>
          <InheritedMenu items={actionItems} ref={menu} popupAlign='left' />
        </div>

        <div
          onClick={() => {
            setActionItems(actions);
            setSelectedOrder(order.id);
          }}
        >
          <MoreActionMobile actions={actions} />
        </div>
      </>
    );
  };

  const header = (
    <div className='flex flex-col md:flex-row gap-3 justify-between'>
      <div className='text-neutral-1 text-lg-semibold'>
        <Translate value='payment_requests' />
        <span className='ml-1 text-xs-medium px-[8px] py-[2px] rounded-[16px] bg-neutral-surface-dark'>
          {totalRecords}
        </span>
      </div>
      {!isDashboard && (
        <div className='flex justify-around gap-2 items-center'>
          <TableSearch
            globalFilterValue={globalSearch}
            onGlobalFilterChange={(e) => {
              setGlobalSearch(e.target.value);
            }}
          />
          <div className={`ml-2 relative ${activeFilterCount ? 'mr-1' : ''}`}>
            <Button
              severity='secondary'
              className='p-button-text h-12'
              onClick={() => {
                setModalVisible(true);
              }}
            >
              <Filter size={20} />
              <Translate value='filterLabel' className='ml-1 hidden lg:block' />
            </Button>
            {!!activeFilterCount && (
              <Badge value={activeFilterCount} className='bg-primary absolute -top-2 -right-2' />
            )}
          </div>
        </div>
      )}
    </div>
  );

  const handleApplyFilters = (formdata: { [key: string]: string[] | string }) => {
    const filterCount = Object.values(formdata).filter((arr) => arr?.length).length;
    setActiveFilterCount(filterCount);
    setPageParams({
      ...pageParams,
      page: 0,
      first: 0,
    });
    dispatch(updateFilter({ filterKey: 'payments_filter', value: formdata }));
  };

  const cancelPaymentMutation = useMutation({
    mutationFn: async () => {
      if (!selectedOrder) {
        return;
      }
      const response = await PaymentService.cancelPayment(selectedOrder);
      return response;
    },
    onSuccess: () => {
      setShowCancelPaymentConfirmPopup(false);
      dispatch(
        sendToast({
          severity: 'success',
          summary: 'success',
          detail: 'payment.cancel.success.toast.title',
        }),
      );
      rateRequestQuery.refetch();
    },
    onError: (error) => {
      dispatch(
        sendToast({
          severity: 'error',
          summary: 'payment.cancel.error.toast.title',
          detail: error.message,
        }),
      );
    },
  });

  return (
    <>
      <FilterModal
        visible={isModalVisible}
        setFilterModalToggle={setModalVisible}
        filters={filterValues}
        setFilters={setFilterValues}
        title='paymentFilter'
        onApply={handleApplyFilters}
      />
      <DataTable
        className='mt-5 hidden sm:block'
        header={header}
        selectAll={true}
        selectionMode={'checkbox'}
        selection={selectedRates!}
        onSelectionChange={(e: { value: Order[] }) => setSelectedRates(e.value)}
        dataKey='id'
        first={pageParams.first}
        rows={pageParams.rows}
        loading={rateRequestQuery.isFetching}
        onPage={onPage}
        onSort={onSort}
        sortField={sortField}
        sortOrder={sortOrder}
        value={rateRequestQuery.data}
        paginator={true}
        lazy
        paginatorTemplate={PaginatorTemplate}
        totalRecords={totalRecords}
      >
        {/* <Column selectionMode='multiple' headerStyle={{ width: '1rem' }}></Column> */}
        <Column field='id' sortable header={translateWithValues('orderId')} />
        <Column
          field='paymentId'
          header={translateWithValues('payment_id')}
          body={(rateRequest: Order) => (
            <div className='text-sm-medium text-neutral-2'>
              {rateRequest.payments?.[0]?.quote?.fxId}
            </div>
          )}
        />
        <Column
          field='orderingCustomer'
          header={translateWithValues('ordering_customer')}
          body={(rateRequest: Order) => (
            <div className='text-sm-medium text-neutral-2'>
              {rateRequest.payments?.[0]?.debitor?.customerLegalName}
            </div>
          )}
        />
        <Column
          field='buyCurrency'
          header={translateWithValues('buy_currency')}
          body={(rateRequest: Order) => (
            <div className='text-sm-medium text-neutral-2'>
              <CurrencyFlag currencyCode={rateRequest.payments[0]?.buyCurrency} />
            </div>
          )}
        />
        <Column
          field='buyAmount'
          header={translateWithValues('buy_amount')}
          body={(rateRequest: Order) => {
            const buyCurrencyValue = rateRequest.payments[0]?.buyCurrency;
            const currency = currencies.find((val) => buyCurrencyValue === val.isocode);
            const precision = currency?.amountPrecision;
            return rateRequest.payments[0]?.buyAmount ? (
              <FormatAmount
                number={parseFloat(rateRequest.payments[0]?.buyAmount.toString())}
                maximumFractionDigits={precision}
              />
            ) : (
              <></>
            );
          }}
        />
        <Column
          field='sellCurrency'
          header={translateWithValues('sell_currency')}
          body={(rateRequest: Order) => (
            <div className='text-sm-medium text-neutral-2'>
              <CurrencyFlag currencyCode={rateRequest.sellCurrency} />
            </div>
          )}
        />
        <Column
          field='sellAmount'
          sortable
          header={translateWithValues('sell_amount')}
          body={(rateRequest: Order) => {
            const sellCurrencyValue = rateRequest.sellCurrency;
            const currency = currencies.find((val) => sellCurrencyValue === val.isocode);
            const precision = currency?.amountPrecision;
            return rateRequest.sellAmount ? (
              <FormatAmount
                number={parseFloat(rateRequest.sellAmount)}
                maximumFractionDigits={precision}
              />
            ) : (
              <></>
            );
          }}
        />
        <Column
          field='beneficiaryName'
          header={translateWithValues('beneficiary_name')}
          body={(rateRequest: Order) => (
            <div className='text-sm-medium text-neutral-2'>
              {rateRequest.payments?.[0]?.creditor?.accountName}
            </div>
          )}
        />
        <Column
          field='beneficiaryName'
          header={translateWithValues('beneficiary_country')}
          body={(rateRequest: Order) => (
            <CountryFlag countryCode={rateRequest.payments?.[0]?.creditor?.countryOfResidence} />
          )}
        />
        <Column
          sortable
          field='createdAt'
          header={translateWithValues('payment_date')}
          body={(rateRequest: Order) => {
            return getFormattedDate(rateRequest.createdAt);
          }}
        />
        <Column
          field='status'
          sortable
          header={translateWithValues('status')}
          body={(rateRequest: Order) => <Status status={rateRequest.status} />}
        />
        <Column header='Actions' body={actionBodyTemplate}></Column>
      </DataTable>
      <div className='sm:hidden'>
        {!isDashboard && (
          <div className='relative'>
            <TableSearch
              globalFilterValue={globalSearch}
              onGlobalFilterChange={(e) => {
                setGlobalSearch(e.target.value);
              }}
            />
            <div className='absolute sm:relative right-1 top-2 sm:right-auto sm:top-auto'>
              <Button
                severity='secondary'
                className='p-button-text h-8 sm:h-12 border-none sm:border-unset !px-1 sm:px-4'
                onClick={() => {
                  setModalVisible(true);
                }}
              >
                <Filter size={20} />
                <Translate value='filterLabel' className='ml-1 hidden lg:block' />
              </Button>
              {!!activeFilterCount && (
                <Badge value={activeFilterCount} className='bg-primary absolute -top-2 -right-2' />
              )}
            </div>
          </div>
        )}
        {!rateRequestQuery.isFetching ? (
          rateRequestQuery.data?.length ? (
            <>
              {rateRequestQuery.data?.map((rateRequest) => {
                return (
                  <div
                    key={rateRequest.id}
                    className='border-[1px] border-neutral-surface-dark rounded-[12px] p-4 mt-4 mb-4 shadow-c'
                  >
                    <div className='flex justify-between items-center	'>
                      <div>
                        {translateWithValues('orderId')}:{rateRequest.id}
                      </div>
                      <div>
                        <Status status={rateRequest.status} />
                      </div>
                    </div>
                    <div className='border-b-[1px] border-neutral-border mt-4 mb-4'></div>
                    <div className='flex justify-between items-end'>
                      <div className='text-neutral-2 text-lg-semibold'>
                        {rateRequest.payments?.[0]?.debitor?.customerLegalName}
                      </div>
                      {actionBodyTemplate(rateRequest)}
                    </div>
                    <div>{getFormattedDate(rateRequest.createdAt)}</div>
                    <div className='flex items-center justify-between mt-8'>
                      <div className='text-sm-medium text-neutral-2'>
                        <CurrencyFlag currencyCode={rateRequest.payments[0]?.buyCurrency} />
                      </div>
                      <div className='text-sm-medium text-neutral-2'>
                        {rateRequest.payments[0]?.buyAmount}
                      </div>
                      <ArrowRight />
                      <div className='text-sm-medium text-neutral-2'>
                        <CurrencyFlag currencyCode={rateRequest.sellCurrency} />
                      </div>
                      <div className='text-sm-medium text-neutral-2'>{rateRequest.sellAmount}</div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <Translate value='no_available_options' className='pb-4 block' />
          )
        ) : (
          <div className='absolute z-10 inset-0 bg-white/50 grid place-content-center'>
            <ProgressSpinner className='w-10 h-10' />
          </div>
        )}

        <div className='flex justify-between items-center'>
          <Button
            disabled={pageParams.page === 0}
            className='cursor-pointer'
            onClick={() =>
              onPage({
                first: pageParams.first - pageParams.rows,
                rows: pageParams.rows,
                page: pageParams.page - 1,
              })
            }
          >
            <PrevButton />
          </Button>

          <div>
            {translateWithValues('pageOf', {
              current: pageParams.page + 1,
              total: totalPages,
            })}
          </div>
          <Button
            disabled={pageParams.page >= totalPages - 1}
            className='cursor-pointer'
            onClick={() =>
              onPage({
                first: pageParams.first + pageParams.rows,
                rows: pageParams.rows,
                page: pageParams.page + 1,
              })
            }
          >
            <NextButton />
          </Button>
        </div>
      </div>
      {showCancelPaymentConfirmPopup && (
        <Confirm
          confirmLabel='payments.table.cancel_payment'
          headerIcon={<WarningIcon />}
          title='payments.table.cancel_payment.confirm.title'
          message='payments.table.cancel_payment.confirm.message'
          confirmButtonSeverify='danger'
          onCancel={() => {
            setShowCancelPaymentConfirmPopup(false);
          }}
          onConfirm={() => cancelPaymentMutation.mutate()}
          visible={showCancelPaymentConfirmPopup}
          loading={cancelPaymentMutation.isPending}
        />
      )}
    </>
  );
};

export default PaymentsTable;
