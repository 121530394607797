import { Dialog } from 'primereact/dialog';
import { Translate } from '../../../../i18n/translate';
import { Button } from 'primereact/button';
import { useQuery } from '@tanstack/react-query';
import PaymentService from '../../paymentService';
import { Checkmark } from '@carbon/icons-react';
import { convertCalenderDateFormat, convertToTimeFormat } from '../../../../utils/helper';
import './index.scss';

interface PaymentHistoryProps {
  showHistory: boolean;
  setShowHistory: (_boolean: boolean) => void;
  orderId: number | null;
}

const PaymentHistory = ({ showHistory, setShowHistory, orderId }: PaymentHistoryProps) => {
  const historyQuery = useQuery({
    queryKey: [`payment_history_${orderId}`],
    queryFn: async (): Promise<PaymentHistory[]> => {
      const data = await PaymentService.getPaymentHistory(orderId ?? 0);
      return data;
    },
  });
  return (
    <Dialog
      draggable={false}
      className='payment-history-sidebar w-full max-w-[400px] scale max-h-[100%] transform scale-100 m-0 !rounded-none full-screen-dialog'
      header={
        <div>
          <Translate value='event.history' />
        </div>
      }
      visible={showHistory}
      style={{ height: '100vh', position: 'fixed', top: 0, right: 0 }}
      onHide={() => setShowHistory(false)}
      footer={
        <Button severity='secondary' onClick={() => setShowHistory(false)}>
          <Translate value='cancel' />
        </Button>
      }
    >
      <div>
        <div className='mt-10'>
          {historyQuery?.data?.map((history, index) => (
            <div key={index} className='grid grid-cols-[3fr_1fr_7fr] mb-1 pb-6 relative'>
              <div className='text-right mr-4'>
                <div> {convertCalenderDateFormat(history.eventTime)}</div>
                <div> {convertToTimeFormat(history.eventTime)} </div>
              </div>
              <div>
                <div className='absolute top-0 left-[10rem] h-full bg-primary w-[0.214rem]' />

                <div className='relative bg-primary w-8 h-8 flex items-center justify-center rounded-full'>
                  <Checkmark color='white' size={18} />
                </div>
              </div>
              <div className='flex-1 pb-8 bg-red text-selected text-sm-semibold'>
                <Translate value={history.eventType} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Dialog>
  );
};

export default PaymentHistory;
